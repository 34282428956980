.Map {
  width:100%;
  height:20em;
}

.detailsCard {
  display: flex;
  height: 5em;
  height: auto;
  width: 7em;
  bottom: 0;
  right: 0;
  margin: 0.5em;
  position: absolute;
}

.markerImg {
  top: -30px;
  left: -15px;
  position: absolute;
}

.markerImg:hover {
  cursor: pointer;
}

.markerImgSelected {
  top: -30px;
  left: -15px;
  position: absolute;
  border: 3px solid #555;
  border-radius: 50%;
  border-color: rgb(171, 8, 35);
}

.markerImgSelected:hover {
  cursor: pointer;
}

/* Action area above map begin */
.MapAction {
  margin: 0.5em;
}

.watcherAction > Button {
  font-weight: bold;
  font-size: medium;
}
/* Action area above map end */


.watcherDelete {
  /* TODO Margin will be raplced by correct icon cors on map */
  color: #555;
}