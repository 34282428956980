h2 {
    color: #757575
}

p {
    color: #757575;
}

Card {
    background-color: red;
}

.optionCard {
    width:32em;
}

.optionMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.optionLeft {

}

.optionPrice > p {
    font-size: 22px;
    margin: 16px;
    text-align: left;
    width: 4.5em;
}

.optionSize > p {
    font-size: 22px;
    margin: 16px;
    text-align: left;
    width: 4.5em;
}

.optionRooms > p {
    font-size: 22px;
    margin: 16px;
    text-align: left;
    width: 4.5em;
}

.optionAge > p {
    font-size: 22px;
    margin: 16px;
    text-align: left;
    width: 4.5em;
}

.optionRight {

}

.optionPrice {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 6fr;
    align-items: center;
    text-align: left;
}

.optionSize {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 6fr;
    align-items: center;
}

.optionRooms {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 6fr;
    align-items: center;
}

.optionAge {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 6fr;
    align-items: center;
}

.checkBoxGroup {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;    
    margin-left: 12px;
    margin-right: 12px;
}

.checkBoxGroupItem {
    display: flex;
    align-items: center;
}

.checkBoxGroupItem > div {
    margin-left: auto;
    justify-self: flex-end;
    
}

.checkBoxGroupItem > div > img {
    margin-right: 20px;
    cursor: pointer;
}

.checkBoxGroup > label {
    color: #757575;
}

.MuiSlider-valueLabel {
    background-color: unset !important;
    top: 0 !important;
}

.MuiSlider-valueLabelLabel {
    color: #757575 !important;
}

.optionDateAdded {
    margin: 0.5em;
    text-align: left;
}

@media only screen and (max-width: 768px) {

    .checkBoxGroup {
        
    }

    .checkBoxGroupItem > div {
        display: none;
    }
    
    .checkBoxGroupItem > div > img {
        cursor: pointer;
        margin-right: 0px;
        padding-right: 0px;

    }

    .optionCard {
        width:100%;
    }
    
    .optionMain {
        display: grid;
        grid-template-columns: 1fr;
    }
    
    .optionLeft {
    }

    .css-46bh2p-MuiCardContent-root {
        padding: 0;
    }

    .optionDateAdded {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 0.5em;
        font-size: 22px;
    }
    
    .optionPrice > p {
        font-size: 22px;
        padding-left: 0.5em;
        text-align: left;
    }
    
    .optionSize > p {
        font-size: 22px;
        padding-left: 0.5em;
        text-align: left;
    }
    
    .optionRooms > p {
        font-size: 22px;
        padding-left: 0.5em;
        text-align: left;
    }
    
    .optionAge > p {
        font-size: 22px;
        padding-left: 0.5em;
        text-align: left;
    }
    
    .optionRight {
    
    }
    
    .optionPrice {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 6fr;
        align-items: center;
        text-align: left;
        padding-right: 0.5em;
    }
    
    .optionSize {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 6fr;
        align-items: center;
        padding-right: 0.5em;
    }
    
    .optionRooms {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 6fr;
        align-items: center;
        padding-right: 0.5em;
    }
    
    .optionAge {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 6fr;
        align-items: center;
    }
    
    .checkBoxGroup {
        align-items: center;
        padding-left: 0.5em;
        font-size: 22px;
    }

  }