.App {  
    text-align: center;
    background-color: rgb(251, 251, 251);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .HeaderSection {
    width: 100%;
  }

  .MapActions {
    margin: 10px;
  }
  
  .MapSection {
    max-width: 32em;
    width: 100%;
  }
  
  .OptionSection {
    max-width: 32em;
    width: 100%;
  }
  
  .EmailSection {
  }
  
  .ActionSection {
  }
  
  .SubscribeSection {
    max-width: 32em;
    width: 100%;
    height: 5em;
    margin-top: 0.5em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid rgb(243, 243, 243);
  
  }
  
  @media only screen and (max-width: 768px) {
    .MapSection {
      min-width: 100%;
    }
  
    .OptionSection {
      max-width: 32em;
      width: 100%;
    }
  }
  